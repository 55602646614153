import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { ethers } from "ethers";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import "./css/styles.css";
import "./resolve-app/style.css";
import "./domains/domainstyles.css";
import "./css/about.css";
import { contractABI, contractAddress } from "./config";
import logo from './transparent_background_image.png';


// Lazy load components
const Profile = lazy(() => import("./Profile"));
const Resolver = lazy(() => import("./resolve-app/resolve"));
const Domains = lazy(() => import("./domains/domains"));
const About = lazy(() => import("./About"));

const App = () => {
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [domain, setDomain] = useState("");
  const [status, setStatus] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [recordCount, setRecordCount] = useState("0000000");
  const [registrationYears, setRegistrationYears] = useState(1);
  const canvasRef = useRef(null);

  const registrationPrice = "1000";
  const subdomainRegistrationPrice = "200";

  const connectWallet = async () => {
    if (account) {
      setAccount(null);
      setBalance(null);
      return;
    }

    try {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              2016: "https://mainnet-rpc.mainnetz.io"
            },
          },
        },
      };

      const web3Modal = new Web3Modal({ cacheProvider: false, providerOptions });
      const instance = await web3Modal.connect();
      const web3 = new Web3(instance);

      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);

      const balance = await web3.eth.getBalance(accounts[0]);
      setBalance(web3.utils.fromWei(balance, "ether"));
    } catch (error) {
      console.error("Failed to connect via Web3Modal:", error);
    }
  };

  const showSuccessMessage = (message) => {
    setStatus(message);
    setTimeout(() => {
      setStatus("");
    }, 5000);
  };

  const checkDomainAvailability = async () => {
  // Use a specific RPC URL for better reliability, e.g., Thirdweb's RPC URL for the Goerli testnet
  const rpcUrl = "https://mainnet-rpc.mainnetz.io";  // Replace with the appropriate RPC for your network
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

  const contract = new ethers.Contract(contractAddress, contractABI, provider);

  const domainParts = domain.split(".");
  const extension = domainParts[domainParts.length - 1].toLowerCase();
  const domainName = domainParts.slice(0, -1).join(".").toLowerCase();
  
  if (!domainName || /\s/.test(domainName)) {
    showSuccessMessage("Domain name cannot contain spaces or be empty.");
    return;
  }
  
  // Check if the extension is .netz
  if (extension !== "netz") {
    showSuccessMessage("Error: Domain must end with .netz.");
    return;
  }

  try {
    const available = await contract.isDomainAvailable(domainName, extension);
    if (available) {
      setShowModal(true);
      showSuccessMessage(`Domain ${domain} is available! Click to register.`);
    } else {
      showSuccessMessage(`Domain ${domain} is already taken.`);
    }
  } catch (error) {
    console.error("Error checking domain availability:", error);
    showSuccessMessage("Error checking domain availability.");
  }
};



  const isSubdomain = (domain) => domain.split(".").length > 2;

  const registerDomain = async () => {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(contractABI, contractAddress);
  const domainParts = domain.split(".");
  const extension = domainParts[domainParts.length - 1].toLowerCase();
  const domainName = domainParts.slice(0, -1).join(".").toLowerCase();
  const isSubdomainFlag = domainParts.length > 2;

  // Check if the extension is .netz
  if (extension !== "netz") {
    showSuccessMessage("Error: Domain must end with .netz.");
    return;
  }

  try {
    const txParams = {
      from: account,
      value: web3.utils.toWei(isSubdomainFlag ? subdomainRegistrationPrice : registrationPrice, "ether"),
    };

    // Detect if the network supports EIP-1559
    const latestBlock = await web3.eth.getBlock("latest");
    if (latestBlock.baseFeePerGas) {
      // EIP-1559 is supported
      txParams.maxFeePerGas = await web3.eth.getMaxFeePerGas();
      txParams.maxPriorityFeePerGas = await web3.eth.getMaxPriorityFeePerGas();
    } else {
      // Fallback to legacy gas price
      txParams.gasPrice = await web3.eth.getGasPrice();
    }

    await contract.methods
      .registerDomain(domainName, extension, account)
      .send(txParams);

    showSuccessMessage("Domain registration successful!");
    setShowModal(false);
  } catch (error) {
    console.error("Error registering domain:", error);
    showSuccessMessage("Error registering domain.");
  }
};


  const fetchRecordCount = async () => {
  try {
    // Define RPC URL for your blockchain
    const RPC_URL = "https://mainnet-rpc.mainnetz.io"; // Replace with your blockchain’s RPC URL

    // Always use the RPC provider for this read-only call
    const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL));

    const contract = new web3.eth.Contract(contractABI, contractAddress);

    // Attempt to call the record count method without relying on user wallet
    const count = await contract.methods.recordCount().call();
    const paddedCount = count.toString().padStart(7, "0");

    setRecordCount(paddedCount);
  } catch (error) {
    console.error("Error fetching record count:", error);
  }
};

useEffect(() => {
  fetchRecordCount();
  const interval = setInterval(fetchRecordCount, 10000);
  return () => clearInterval(interval);
}, []);




  return (
    <Router>
      <div className="App">
        <div className="nav-bar">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Logo" className="logo-image" />
              <span className="block-part">BlockDomainZ</span>
            </Link>
          </div>
          <div className="nav-right">
            <button onClick={connectWallet} className="modern-button">
              {account ? "Connected" : "Connect"}
            </button>
            <div className="hamburger-menu" onClick={() => setShowMenu(!showMenu)}>
              &#9776;
            </div>
          </div>
        </div>

        {showMenu && (
          <div className="dropdown-menu">
            <Link to="/profile" className="dropdown-item">My Profile</Link>
            <Link to="/transfer" className="dropdown-item">Transfer</Link>
            <Link to="/domains" className="dropdown-item">Domains</Link>
			<Link to="/about" className="dropdown-item">About</Link>
          </div>
        )}

        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={
              <>
                <div className="cube-container">
                  <div className="cube">
                    <div className="top"></div>
                    <div className="side">
                      <span style={{ '--i': 0 }}></span>
                      <span style={{ '--i': 1 }}></span>
                      <span style={{ '--i': 2 }}></span>
                      <span style={{ '--i': 3 }}></span>
                    </div>
                  </div>
                </div>

                <div className="search-container">
                  <input
                    type="text"
                    className="search-bar"
                    value={domain}
                    placeholder="Search for a domain"
                    onChange={(e) => setDomain(e.target.value)}
                  />
                  <button onClick={checkDomainAvailability} className="modern-button">
                    Search
                  </button>
                </div>

                {account && (
                  <div className="wallet-info">
                    <p>Bal: {parseFloat(balance).toFixed(2)} NetZ</p>
                  </div>
                )}

                <div className="record-counter">
                  {recordCount.split("").map((digit, index) => (
                    <div key={index} className="counter-block">
                      {digit}
                    </div>
                  ))}
                </div>

                <canvas ref={canvasRef} id="interactive-background"></canvas>

                {showModal && (
                  <div className="modal-overlay">
                    <div className="modal">
                      <h2>Register {domain}</h2>
                      <h2>Price: {parseFloat(isSubdomain(domain) ? subdomainRegistrationPrice : registrationPrice) * registrationYears} NetZ</h2>
                      <label>Choose registration period:</label>
                      <input
                        type="range"
                        min="1"
                        max="1"
                        value={registrationYears}
                        onChange={(e) => setRegistrationYears(e.target.value)}
                      />
                      <p>{registrationYears} year(s)</p>

                      {account ? (
                        <button onClick={registerDomain} className="modern-button">Register</button>
                      ) : (
                        <button onClick={connectWallet} className="modern-button">Connect Wallet</button>
                      )}
                      <button onClick={() => setShowModal(false)} className="modern-button">Close</button>
                    </div>
                  </div>
                )}

                {status && (
                  <div className={status.includes("successful") ? "success-message" : "error-message"}>
                    {status}
                  </div>
                )}
              </>
            } />

            <Route path="/profile" element={<Profile account={account} />} />
            <Route path="/transfer" element={<Resolver account={account} />} />
            <Route path="/domains" element={<Domains account={account} />} />
			<Route path="/about" element={<About />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
